import styled from 'styled-components';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import New from './pages/New';
import Lotto from './pages/Lotto';
import Affiliate from './pages/Affiliate';
import Api from './pages/Api';
import Future from './pages/Future';
import HomePage from './pages/HomePage';
import MobileApi from './pages/MobileApi';
import MobileAffiliate from './pages/MobileAffiliate';
import { isMobile } from 'react-device-detect';


//<Route exact path="/" element={isMobile ? <HomePage /> : <New />} />   
const Container = styled.div`
  width: 100%;
  max-width: 100%,
  `

function App() {

  const HomePageOrNew = () => {
    return isMobile ? <HomePage /> : <New />;
  };
  return (
    <Container>
    
      <BrowserRouter>
        
        <Routes>
          <Route exact path="/" element={<HomePageOrNew />} />         
          <Route exact path="/lotto" element={<Lotto />} />
          <Route exact path="/affiliate" element={<Affiliate />} />
          <Route exact path="/api" element={<Api />} />
          <Route exact path="/future" element={<Future />} />
          <Route exact path="/mobileapi" element={<MobileApi />} />
          <Route exact path="/mobileaffiliate" element={<MobileAffiliate />} />





          

          

        </Routes>
      </BrowserRouter>

    </Container>
   
  );
}

export default App;
