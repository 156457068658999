import React, { useState } from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    border: 0.5px solid #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`
const QuestionNumber = styled.h3`
    font-family: Work Sans;
    color: white;
    font-size: 12px;
    font-weight: 400;
`

const HomeFAQItem = ({ question, answer, isOpen, toggleAnswer, index }) => {
  return (
    <Container>
      <Wrapper>
        <QuestionWrapper onClick={toggleAnswer}>
          <IconContainer>
            <QuestionNumber>0{index + 1}</QuestionNumber>
          </IconContainer>
          <Question>{question}</Question>
        </QuestionWrapper>
        <Answer isOpen={isOpen}>{answer}</Answer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: auto;
  
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 70%;
  max-width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #45D9F4;
  
`;

const QuestionWrapper = styled.div`
  cursor: pointer;
  padding: 15px 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Question = styled.div`
  font-weight: 600;
  color: white;
  text-align: center;
  font-family: Work Sans;
  font-size: 12px;
`;

const Answer = styled.div`
  padding: ${({ isOpen }) => (isOpen ? '10px 25px' : '0')};
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  overflow: hidden;
  transition: padding 0.3s ease, height 0.3s ease;
  color: #8e8e8e;
  font-weight: 600;
  font-family: Work Sans; 
  font-size: 12px;
  margin-left: 50px;
`;

export default HomeFAQItem;
