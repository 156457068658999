import styled from 'styled-components';
import { useState } from 'react';
import Headerhand from '../assets/Headerhand.png';
import { Icon } from '@iconify/react/dist/iconify.js';
import CircleImage from '../assets/CircleImage.png'
import Footer from '../components/Footer';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import AndroidImg from '../assets/android.png';
import SecondContainer from '../components/Home/SecondContainer';
import ThirdContainer from '../components/Home/ThirdContainer';
import FourthContainer from '../components/Home/FourthContainer';
import NewFooter from '../components/NewFooter';
import googlePlay from '../assets/googlePlay.png';
import appStore from '../assets/AppStore.png';
import HomeFAQList from '../components/Home/HomeFAQList';
import FifthContainer from '../components/Home/FifthContainer';
import SixthContainer from '../components/Home/SixthContainer';
import OpacityLogo from '../assets/OpacityLogo.png';
import EmailModal from '../components/EmailModal';



const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
;
    @media screen and (max-width: 900px) {
        height: auto;
        padding-bottom: 20px;
      }
    `
const TopOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0.7));
    `
const BottomOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(20, 20, 20, 1), rgba(20, 20, 20, 1), rgba(20, 20, 20, 1), rgba(20, 20, 20, 0.7));
    `
const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    z-index: 99;
    margin-top: 100px;
    @media screen and (max-width: 900px) {
    margin-top: 20px;
    }
    `
const InfoDivA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    z-index: 99;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
    top: 100px;
    }
    `
const TitleText = styled.div`
    color: white;
    font-size: 58px;
    font-family: Work Sans;
    font-weight: 900;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    font-size: 38px;
    text-align: center;
    }
    
    `
export const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
  `
export const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.15) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
  `
export const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
  `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.3) 30%, #1A0135 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -150px;
    right: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
`
const SubTitle = styled.div`
    color: #999999;
    font-size: 22px;
    font-weight: 500;
    font-family: Work Sans;
    text-align: center;
    margin-bottom: 40px;
    
    `
const SubTitleA = styled.div`
    color: white;
    font-size: 20px;
    font-weight: 400;
    font-family: Work Sans;
    text-align: center;
    margin-bottom: 5px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 90vw;}
    `
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
        flex-direction: column;
      }
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    padding: 15px;
    margin: 10px;
    border: 2px solid #FF4DCF;
    width: 200px;
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 18px
    `
const IconContainer = styled.div`
    position: absolute;
    top: 30%;
    `
const AndroidImage = styled.img`
    z-index: 999;
    margin-top: 25px;
    @media screen and (max-width: 900px) {
    display: none;
    `
const MasterContainer = styled.div`
    `
const Image = styled.img`
    width: 175px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
    @media screen and (max-width: 900px) {
    width: 200px;
    height: 65px;
    margin-top: 20px;
    }
    `

    

const New = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);
    

    return (
    <MasterContainer>
        <Container>
            <Header />
            <RadialGradient />
            <RadialGradientA />
            <RadialGradientB />
            <InfoDiv>
                <TitleText>Welcome to Hydrix</TitleText>
                <SubTitle>Hydrix is a community-driven platform available on both
                    Android and iOS, designed to support your journey toward greater
                    independence. Simply download the app, engage daily by opening
                    it and tapping to keep your mining active, and move closer to 
                    achieving your personal goals.
                </SubTitle>
                <ButtonRow>
                    <Image src={googlePlay} />
                    <Image src={appStore} />
                    
                </ButtonRow>
            </InfoDiv>
            <InfoDivA>
                <SubTitleA>Dont miss our launch promotion!</SubTitleA>
                <SubTitleA>Get notified</SubTitleA>
                <ButtonA onClick={openModal}>
                    <ButtonText>Sign up</ButtonText>
                </ButtonA>
            </InfoDivA>
            <EmailModal isVisible={isModalVisible} onClose={closeModal} />
           
            
            
        </Container>
        <SecondContainer RadialGradient={RadialGradient} />
        <ThirdContainer RadialGradientB={RadialGradientB} RadialGradient={RadialGradient} />
        <FifthContainer />
        <FourthContainer RadialGradientB={RadialGradientB} RadialGradientA={RadialGradientA} />
        <SixthContainer RadialGradient={RadialGradient} RadialGradientB={RadialGradientB} />
        <NewFooter />
    </MasterContainer>


        
            
        

    )
}

export default New;