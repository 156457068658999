import React from 'react';
import HomeFAQItem from './HomeFAQItem';
import { useState } from 'react';
import styled from 'styled-components';



const HomeFAQList = () => {
  const faqs = [
    { question: 'Is Hydrix another blockchain ecosystem?', answer: 'No. Hydrix is a token that will be mined off chain and deployed on a blockchain when mining stops.' },
    { question: 'Where will Hydrix be deployed?', answer: 'No decision has yet been made as to which blockchain Hydrix will call home. However, gas price, speed, and security will inform our decision.' },
    { question: 'When will Hydrix be deployed?', answer: 'There is no fixed timeline for deployment. However, the community will be kept fully informed.' },
    { question: 'What about liquidity after TGE?', answer: 'You can read about liquidity in our whitepaper. TLDR liquidity will be generated via app & web based advertising in addition to seeking partners/investors and incentivising the community to add liquidity.'},
    { question: 'Is the app secure?', answer: 'Yes. Any personal information held by Hydrix is encrypted and held on secure, protected servers. In addition, the mining algorythm can detect and report unusual activity and can take regular snapshots so all balances are secured every 12 hours.'},
    { question: 'Have you considered the TGE yet?', answer: 'Yes. We are currently considering launching on Solana, Avalanche, or Algorand but a decision is yet to be made on this. Yet, we remain open to all current blockchains.'},
    { question: 'Where will I be able to trade Hydrix', answer: 'Initially, we would prefer to launch on a Dex but if we receive an offer from a Cex we will consider it on its merits. Eventually, we would be listed in as many places as possible.'},
    

];

const [openIndex, setOpenIndex] = React.useState(null);

const toggleAnswer = (index) => {
  setOpenIndex(openIndex === index ? null : index);
};

  return (
  
    <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
      {faqs.map((faq, index) => (
        <HomeFAQItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isOpen={openIndex === index}
          toggleAnswer={() => toggleAnswer(index)}
          index={index}
        />
      ))}
    </div>
    
  );
};

export default HomeFAQList;
