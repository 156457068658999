import styled from "styled-components";
import ScreenShot from '../../assets/Screenshot.png';
import ScreenShot2 from '../../assets/ScreenShot2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";


const Container = styled.div`
    width: 100%;
    height: auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    background-color: #00011c;
    margin-bottom: 30px;
    `
const TitleText = styled.div`
    color: #00011c;
    font-size: 32px;
    font-family: Work Sans;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: left;
    margin: 0 auto;
    width: 80%;
    background-color: white;
    margin-top: 15px;
    padding: 15px;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #45D9F4;
    font-family: Pridi;
    text-align: left;
    margin: 0 auto;
    width: 80%;
    padding: 15px;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #FF4DCF;
    height: 60px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #FF4DCF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    `
const ImageRowA = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const ImagePhone = styled.img`
    width: 70%;
    height: auto;
    `
const HomePageSecond = () => {
    return (
        <Container>
            <Wrapper>
                <TitleText>Join the Conversation</TitleText>
                <Subtitle>Join thousands of users and get the latest updates</Subtitle>
                <ImageRow>
                    <ImagePhone src={ScreenShot2} />
                </ImageRow>
                <ImageRowA>
                <a href="https://www.t.me/HydrixApp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', display: 'inline-block', width: '80%' }}>
                    <ButtonA>
                        <ButtonText>Get Updates</ButtonText>
                        <FontAwesomeIcon icon={faChevronRight} color="#1A0135" size="2" />
                    </ButtonA>
                </a>
                </ImageRowA>
                
                
            </Wrapper>
            
        </Container>
    )
};

export default HomePageSecond;
