import styled from "styled-components";
import Logo from '../../assets/Logo.png';

const Container = styled.div`
    width: 100%;
    height: auto; 
    display: flex;
    flex-direction: column;
    background-color: #110122;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 30px;
    `
const TitleText = styled.div`
    color: #FFFFFF;
    font-size: 42px;
    font-family: Work Sans;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 60px;
    height: auto;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #45D9F4;
    font-family: Work Sans;
    margin-bottom: 60px;
    `
const SubtitleFooter = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: white;
    font-family: Work Sans;
    margin-bottom: 15px;
    &:hover {
    color: #45D9F4 }
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #00FAD9;
    height: 60px;
    width: 80%;
    margin-top: 30px;
    background-color: #00FAD9; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #00011c;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
    `
const ImageIcon = styled.img`
    height: 40px;
    width: 40px;
    `
const HomePageFifth = () => {

    const currentYear = new Date().getFullYear();

    return (
        <Container>
            <Wrapper>
                <a href="https://drive.google.com/file/d/1n-9f722ZHgD2vCpS5SF2or1FAgBBIilf/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <SubtitleFooter>Data Protection</SubtitleFooter>    
                </a>                
                <a href="https://drive.google.com/file/d/1d4BWfbYhY0YmxKiP9MkJ0qed0K-JNfWP/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <SubtitleFooter>AML</SubtitleFooter>
                </a>
                <a href="https://drive.google.com/file/d/1D3Snt0OJaCnzB-xW3Eaz1YVyOOL8gjJQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <SubtitleFooter>Terms of Service</SubtitleFooter>
                </a>
                <SubtitleFooter>Privacy Policy</SubtitleFooter>
                <a href="https://drive.google.com/file/d/1aBYwOoAaSDyh3AcMPMo4sIIsPPKvT0SB/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <SubtitleFooter style={{ marginBottom: '60px' }}>Whitepaper</SubtitleFooter>
                </a>
                <SubtitleFooter>Android Download</SubtitleFooter>
                <SubtitleFooter>ios Download</SubtitleFooter>
                <ImageRow>
                    <SubtitleFooter>
                        Copyright Hydrix LTD @ {currentYear}
                    </SubtitleFooter>
                </ImageRow>
                           
            </Wrapper>
            
        </Container>
    )
};

export default HomePageFifth;
