import styled from "styled-components";
import apiIcon from '../../assets/apiIcon.png';
import Document from '../../assets/document.png';
import Affiliate from '../../assets/affiliate.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import { Link } from 'react-router-dom';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00011c; 
    @media screen and (max-width: 900px) {
    height: auto;}
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 70%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    height: 500px;
    @media screen and (max-width: 900px) {
    width: 90%%
    max-width: 90%;
    margin-top: 25px;
    flex-direction: column;
    height: auto;}
    `
const InnerContainerColumn = styled.div`
    height: 100%;
    display: flex;
    width: 49%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    width: 90%;
    height: auto; }
    `
const TopMasterRow = styled.div`
    width: 70%;
    max-width: 70%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    @media screen and (max-width: 900px) {
    margin-bottom: 15px;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    `
const ApiComponent = styled.div`
    width: 100%;
    height: 48%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 0.5px solid #333333;
    `
const AffiliateComponent = styled.div`
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 0.5px solid #333333;
    `
const ApiComponentRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    }
    `
const ApiComponentColumn = styled.div`
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    @media screen and (max-width: 900px) {
    width: 90%;
    justify-content: center;
    align-items: center;
    }
    `
const AffiliateComponentColumn = styled.div`
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    @media screen and (max-width: 900px) {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    text-align: center;
    margin-left: 0;
    }
    `
const DownloadComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    `
const IconContainerA = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border: 1px solid #262626;
    border-radius: 24px;
    background: linear-gradient(to right, rgba(15, 15, 15, 1), rgba(15, 15, 15, 1), rgba(15, 15, 15, 0.9), rgba(15, 15, 15, 0.8), rgba(15, 15, 15, 0.3));
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 24px;
    @media screen and (max-width: 900px) {
    font-size: 18px;}
    `
const TitleTextA = styled.div`
    color: white; //#001226;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    @media screen and (max-width: 900px) {
    font-size: 18px;
    width: 100%;
    text-align: center;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 14px;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;}
    `
const AffSubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
    margin-right: 40px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;
    margin-right: 0;}
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    &:hover {
    background-color: #999999;
    }
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-bottom: 20px;}
`
const Image = styled.img`
    height: 35px;
    width: 35px;
    `


const FourthContainer = ({ RadialGradientA, RadialGradientB }) => {
    return (
        <Container>
            {!isMobile && <RadialGradientA />}
            {!isMobile && <RadialGradientB />}
            <TopMasterRow>
                <TopMasterRowColumn>
                    <TitleTextA>Take a Deeper Dive</TitleTextA>
                    <SubTitleText>Check out Hydrix from a more technical perspective!</SubTitleText>
                </TopMasterRowColumn>
                    <IconContainerA>
                        <FontAwesomeIcon icon={faArrowRight} color="white" size="1x" />
                    </IconContainerA>
            </TopMasterRow>
            <InnerContainer>
                <InnerContainerColumn>
                    <ApiComponent>
                        <ApiComponentColumn>
                            <IconContainer>
                                <Image
                                    src={apiIcon}
                                />
                            </IconContainer>
                        </ApiComponentColumn>
                        <ApiComponentColumn style={{ marginRight: '20px' }}>
                            <TitleText>Hydrix API</TitleText>
                            <SubTitleText>We believe in fairness through transparency. 
                                Developers can access our free API, granting 
                                access to 16 MI data feeds. Read here to find out more
                            </SubTitleText>
                            <Link to="/api">
                                <ButtonA>
                                    <ButtonText>Coming soon!</ButtonText>
                                </ButtonA>
                            </Link>
                        </ApiComponentColumn>
                    </ApiComponent>
                    <ApiComponent>
                        <ApiComponentColumn>
                            <IconContainer>
                                <Image
                                    src={Document}
                                />
                            </IconContainer>
                        </ApiComponentColumn>
                        <ApiComponentColumn style={{ marginRight: '20px' }}>
                            <TitleText>Technical Docs</TitleText>
                            <SubTitleText>If you want to take a look under the hood
                                at Hydrix we have composed a technical walkthrough of everything
                                that goes on behind the scenes.
                            </SubTitleText>
                            <ButtonA>
                                <ButtonText>GitBook</ButtonText>
                            </ButtonA>
                        </ApiComponentColumn>
                    </ApiComponent>
                </InnerContainerColumn>
                <AffiliateComponent>
                    <ApiComponentColumn style={{ height: '90%' }}>
                        <IconContainer>
                            <Image
                                src={Affiliate}
                            />
                        </IconContainer>
                    </ApiComponentColumn>
                    <AffiliateComponentColumn>
                        <TitleText>Affiliate Programme</TitleText>
                        <AffSubTitleText>To help expand our community, Hydrix aims to fully support users
                                that put Hydrix at the centre of everything they do. You dont need to be 
                                an influencer to join the affiliate programme, just someone willing to promote 
                                and educate others about Hydrix.
                        </AffSubTitleText>
                        <AffSubTitleText>In return Hydrix has a number of ways to reward our affiliates, from our community
                            bonus scheme to a limited 'after-miner'. Read about it here.
                        </AffSubTitleText>
                        <Link to="/affiliate">
                            <ButtonA>
                                <ButtonText>Interested?</ButtonText>
                            </ButtonA>
                        </Link>
                    </AffiliateComponentColumn>
                </AffiliateComponent>
            </InnerContainer>
        </Container>

    )
};

export default FourthContainer;