import styled from "styled-components";
import NewFooter from "../components/NewFooter";
import { useState } from "react";
import Header from "../components/Header";
import One from '../assets/one.png';
import Two from '../assets/number-2.png';
import Three from '../assets/number-3.png';
import Web3Wallet from '../assets/web3Wallet.png';
import Arrow from '../assets/arrows.png';
import { isMobile } from 'react-device-detect';





const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    `
const InnerInnerContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    flex-direction: column;
    }
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    }
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 900px;
    left: -50px;
    zIndex: 19;
    `
const ImageIcon = styled.img`
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    `
const ImageIconA = styled.img`
    width: 45px;
    height: 45px;
    `
const ImageIconC = styled.img`
    width: 500px;
    height: 300px;
    `
const ImageIconB = styled.img`
    width: 20px;
    height: 20px;
    padding-bottom: 20px;
    margin-right: 40px;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 25px;
    flex-direction: column;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    width: 100%;
    align-items: center;
    justify-content: center;}
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    font-size: 18px;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;}
    `
const SubTitleTextB = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 80%;
    text-align: center;}
    `
const SubTitleTextC = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    text-align: right;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 80%;
    text-align: center;}
    `
const ResourceColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 150px;
    height: 200px;
    text-align: center;
    `
const ResourceTitle = styled.div`
    font-family: Work Sans;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    `
const ResourceSubtitle = styled.div`
    font-family: Work Sans;
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    tex-align: center;
    width: 90%;
    `
const CommunityComponentA = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: space-around;
    height: 300px;
    @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;}
    `
const CommunityRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    `
const CommunityComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 48%;
    background-color: #1A0135;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    display: none;

    `
const IconContainer = styled.div`
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;}
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: rgba(0, 250, 217, 1);
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const FormContainer = styled.form`
    width: 90%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `
const Input = styled.input`
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: rgba(0, 250, 217, 0.1);
    `
const HowWhatColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 22%;
    height: 375px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
    height: auto;
    width: 80vw;
    margin-bottom: 40px;}
    `
const HowWhat = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #555555;
    border-radius: 4px;
    height: 300px;
    padding: 20px 20px 20px 40px;
    margin-bottom: 20px;
    justify-content: center;
    @media screen and (max-width: 900px) {
    width: 90vw;
    align-items: center;
    padding-bottom: 20px;
    height: auto;}
    `
const Future = () => {
    return (
        <Container>
            <Header />
            <RadialGradient />
            <RadialGradientA />
            <RadialGradientB />
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Hydrix Web3 Wallet</TitleText>
                        <SubTitleTextB>Hydrix is excited to announce the development of a Web3 wallet
                            that will facilitate the migration of the Hydrix token from Web2 to Web3.
                            This innovative wallet will not only streamline the transition process but
                            also empower users to buy, sell, and trade the Hydrix token seamlessly.
                            Additionally, users will have the capability to trade a wide variety of
                            other tokens, making the Hydrix wallet a versatile and essential tool for
                            all your digital asset management needs in the evolving cryptocurrency
                            landscape.
                        </SubTitleTextB>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerContainer>
                    {!isMobile && <CommunityComponentA>
                        <CommunityRow>
                            <ImageIconB src={One} />
                            <TitleText>Claim Tokens</TitleText>
                        </CommunityRow>
                        <CommunityRow>
                            <ImageIconB src={Two} />
                            <TitleText>Buy, Sell, and Swap</TitleText>
                        </CommunityRow>
                        <CommunityRow>
                            <ImageIconB src={Three} />
                            <TitleText>Store Your Hydrix</TitleText>
                        </CommunityRow>
                    </CommunityComponentA>}
                    <CommunityComponent>
                        <ImageIconC src={Web3Wallet} />
                    </CommunityComponent>
                </InnerInnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Hydrix Afterminer</TitleText>
                        <SubTitleTextB>Hydrix AfterMiner introduces a unique alternative to
                            traditional staking in the ecosystem. By locking up tokens for chosen
                            periods, users gain exclusive access to continue mining even after
                            the Token Generation Event (TGE). Mining rates will be determined
                            by the amount of tokens locked, allowing for tailored mining opportunities
                            based on commitment. AfterMiner also features a fixed staking rewards
                            pool of 10% of the total token supply, available only for the first year.
                            This limited period ensures sustainable growth, as there will be no
                            additional inflation beyond this timeframe.
                        </SubTitleTextB>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerContainer>
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Claim</TitleText>
                            <SubTitleTextB>When the Token Generation Event (TGE) occurs, eligible users
                                will be able to claim their Hydrix tokens seamlessly through our platform.
                                This event marks the official release of the Hydrix token, enabling
                                participants to access and utilize their tokens within the ecosystem.
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={One} />
                    </HowWhatColumn>
                    {!isMobile && <img src={Arrow} />}
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Lock</TitleText>
                            <SubTitleTextB>To access AfterMiner, users can lock their Hydrix tokens for a
                                specified period, securing their place in the mining ecosystem after the
                                Token Generation Event (TGE). By choosing different lock-up periods,
                                participants can unlock tailored mining rates based on their level of
                                commitment. This token-locking mechanism provides ongoing access to mining
                                rewards.
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={Two} />
                    </HowWhatColumn>
                    {!isMobile && <img src={Arrow} />}
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Mine</TitleText>
                            <SubTitleTextB>After the Token Generation Event (TGE), mining in the Hydrix
                                ecosystem enters a new phase where only users who have locked their tokens
                                can access the AfterMiner. This unique approach
                                allows users to mine based on the amount and duration of their locked tokens,
                                enabling flexible mining rates tailored to each participant's commitment.
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={Three} />
                    </HowWhatColumn>
                </InnerInnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>LP Rewards</TitleText>
                        <SubTitleTextB>Hydrix AfterMiner introduces a unique alternative to
                            traditional staking in the ecosystem. By locking up tokens for chosen
                            periods, users gain exclusive access to continue mining even after
                            the Token Generation Event (TGE). Mining rates will be determined
                            by the amount of tokens locked, allowing for tailored mining opportunities
                            based on commitment. AfterMiner also features a fixed staking rewards
                            pool of 10% of the total token supply, available only for the first year.
                            This limited period ensures sustainable growth, as there will be no
                            additional inflation beyond this timeframe.
                        </SubTitleTextB>
                    </TopMasterRowColumn>
                </TopMasterRow>
            </InnerContainer>
            <NewFooter />
        </Container>
    )
};

export default Future;