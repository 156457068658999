import styled from "styled-components";

const Container = styled.div`
    height: auto;
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    max-width: 100%;
    background-color: #001B39;
    `
const InnerContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-contant: center;
  `
const InnerContainerRow = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media screen and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        justify-content: center; }
  
  `
const InnerContainerRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
        justify-content: center;
        align-items: center; }
  
  `
const Title = styled.h2`
  font-family: Work Sans;
  font-size: 22px;
  font-weight: 600;
  color: white;
  `
const LinkText = styled.h3`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  color: #8e8e8e;
  &:hover {
        color: rgba(252, 3, 107, 1); 
    }
  @media screen and (max-width: 900px) {
        padding-left: 0 !important; }
  `


const Footer = () => {

    const currentYear = new Date().getFullYear();

    
        return (
          <Container>
            <InnerContainer>
              <InnerContainerRow>
                <InnerContainerRowColumn>
                  <Title>Docs</Title>
                  <a href="https://drive.google.com/file/d/1n-9f722ZHgD2vCpS5SF2or1FAgBBIilf/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <LinkText>Privacy Policy</LinkText>
                  </a>
                  <a href="https://drive.google.com/file/d/1aBYwOoAaSDyh3AcMPMo4sIIsPPKvT0SB/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <LinkText>Whitepaper</LinkText>
                  </a>
                  <a href="https://drive.google.com/file/d/1d4BWfbYhY0YmxKiP9MkJ0qed0K-JNfWP/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <LinkText>AML Statement</LinkText>
                  </a>
                  <a href="https://drive.google.com/file/d/1D3Snt0OJaCnzB-xW3Eaz1YVyOOL8gjJQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <LinkText>Terms of Service</LinkText>
                  </a>
                </InnerContainerRowColumn>

                <InnerContainerRowColumn>
                  <Title>Contact</Title>
                  <LinkText>Email</LinkText>
                  <a href="https://www.twitter.com/hydrixapp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <LinkText>X.com</LinkText>
                  </a>
                  <LinkText>Telegram</LinkText>
                  <LinkText>Facebook</LinkText>
                </InnerContainerRowColumn>

                <InnerContainerRowColumn>
                  <Title>Download</Title>
                  <LinkText>Android</LinkText>
                  <LinkText>ios</LinkText>
                  
                </InnerContainerRowColumn>

              </InnerContainerRow>
              <LinkText style={{ paddingLeft: '1200px' }}>Copyright Hydrix @{currentYear}</LinkText>
            </InnerContainer>

          </Container>
            
          );
        };

export default Footer;