import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  width: 400px;
  max-width: 90%;
  padding: 20px;
  border-radius: 4px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-out;
  zIndex: 999;
`;

const Button = styled.button`
  background-color: #00FAD9;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `

const ModalButton = styled(Button)`
    margin-top: 20px;
    background-color: #00FAD9;
    width: 150px;
`
const TitleText = styled.div`
    color: #00011c;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 18px;
    @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    text-align: center;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;
    }
    `
const Form = styled.form`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 90%;
    `
const Label = styled.label`
    color: #555555;
    font-size: 12px;
    font-weight: 400;
    font-family: Work Sans;
    `




const EmailModal = ({ isVisible, onClose }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Replace with your server endpoint
      await axios.post('https://chainfree.info:4000/promoemail', { email });
      alert('Email submitted successfully!');
      onClose(); // Close the modal after submission
    } catch (error) {
        console.log(error.response);
        console.error('There was an error submitting the email!', error);
    }
  };

  return (
    <ModalOverlay isVisible={isVisible} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <TitleText>Stay Informed!</TitleText>
        <SubTitleText>Enter your email address to ensure you don't miss our exclusive launch celebration offer</SubTitleText>
        <Form onSubmit={handleSubmit}>
          <Label>
            Email Address:
            <input
              type="email"
              placeholder='Enter email address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ width: '100%', color: 'black', border: 'none', backgroundColor: 'rgba(0, 250, 217, 0.1)', padding: '10px', marginTop: '5px', borderRadius: '4px' }}
            />
          </Label>
            <ModalButton type="submit">
                <ButtonText>Submit</ButtonText>
            </ModalButton>
        </Form>
        
      </ModalContent>
    </ModalOverlay>
  );
};

export default EmailModal;
