import styled from "styled-components";
import fairnessIcon from '../../assets/fairnessIcon.png';
import communityIcon from '../../assets/communityIcon.png';
import developmentIcon from '../../assets/developmentIcon.png';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 70vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: #00011c;
    @media screen and (max-width: 900px) {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    }
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    }
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 25px;
  }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: -50px;
    left: 50px;
    zIndex: 19;
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    `
const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;
    margin-bottom: 0;
    }
    `
const InfoComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 32%;
    border: 0.5px solid #333333; 
    border-radius: 4px;
    align-items: center;
    padding-top: 20px;
    @media screen and (max-width: 900px) {
    width: 90%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    }
    
    `
const InfoComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const InfoComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    justify-content: center;
    text-align: center;}
    `
const ImageIcon = styled.img`
    width: 35px;
    height: 35px;
    `
const InviteTitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 22px;
    @media screen and (max-width: 900px) {
    font-size: 18px;}
    `
const InviteText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;}
`

const ThirdContainer = ({ RadialGradientB }) => {
    return (
        <Container>
            {!isMobile && <RadialGradientA />}
            {!isMobile && <RadialGradientB />}
            
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>About Hydrix</TitleText>
                        <SubTitleText>See what drives the Hydrix team to create and innovate every day</SubTitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InfoContainer>
                    <InfoComponent>
                        <InfoComponentRow>
                            <IconContainer>
                                <ImageIcon src={fairnessIcon} />
                            </IconContainer>
                            <InviteTitleText>Fairness</InviteTitleText>
                        </InfoComponentRow>
                        <InfoComponentColumn>
                            <InviteText>We have process' in place to ensure that the distribution of
                                Hydrix will be based on Mining activity and engagement only.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                        <InfoComponentRow>
                            <IconContainer>
                                <ImageIcon src={communityIcon} />
                            </IconContainer>
                            <InviteTitleText>Community</InviteTitleText>
                        </InfoComponentRow>
                        <InfoComponentColumn>
                            <InviteText>We place our community at the centre of everything we do at Hydrix.
                                We work on your behalf with gratitude.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                        <InfoComponentRow>
                            <IconContainer>
                                <ImageIcon src={developmentIcon} />
                            </IconContainer>
                            <InviteTitleText>Development</InviteTitleText>
                        </InfoComponentRow>
                        <InfoComponentColumn>
                            <InviteText>From inception right through to token launch, we spend each day developing
                                the product to make it better.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                </InfoContainer>
                
            </InnerContainer>
        </Container>

    )
};

export default ThirdContainer;