import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HomePageFifth from "../components/HomePage/HomePageFifth";
import { useState } from "react";
import UserApi from '../assets/userApi.png';
import MobileCasual from '../assets/mobileCasual.png';
import MobileSupporter from '../assets/mobileSupporter.png';
import MobileAffiliates from '../assets/mobileAffiliate.png';


const Container = styled.div`
    width: 100%;
    height: auto;
    background-color: #00011c;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    margin-bottom: 60px;
    `
const TitleText = styled.div`
    color: white;
    font-size: 42px;
    font-family: Work Sans;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    `
const ApiTitleText = styled.div`
    color: white;
    font-size: 30px;
    font-family: Work Sans;
    font-weight: 600;
    margin-top: 60px;
    text-align: left;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: #FF4DCF;
    font-family: Pridi;
    text-align: left;
    `
const SubtitleB = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #45D9F4;
    font-family: Work Sans;
    text-align: left;
    margin-top: 30px;
    font-style: italic;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #45D9F4;
    height: 40px;
    width: 60%;
    margin-top: 30px;
    background-color: #45D9F4;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 10px; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #1A0135;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    `
const ImageRowA = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FF4DCF;
    margin-bottom: 20px;
    `
const ImagePhone = styled.img`
    width: 60%;
    height: auto;
    `
const FormContainer = styled.form`
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
    `
const Input = styled.input`
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #8e8e8e;
    `
const UserAccount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid #333333;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    `
const Message = styled.div`
    font-family: Work Sans;
    font-weight: 400;
    font-size: 10px;
    color: #999999;
    margin: 0 auto;
    margin-top: 10px;
    `
const UserTitleText = styled.div`
    color: #45D9F4;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
   `
const UserInviteText = styled.div`
   color: white;
   font-family: Pridi;
   font-weight: 400;
   font-size: 14px;
   text-align: center;
   width: 80%;
   `
const FloatingButton = styled.button`
    position: fixed;
    top: 15px;
    right: 15px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #45D9F4;
    border: 2px solid #45D9F4;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const FloatingButtonText = styled.div`
    font-family: Work Sans;
    font-weight: 600;
    font-size: 12px;
    color: black;
    `
const MobileAffiliate = () => {


    return (
        <Container>
            
            <Wrapper>
                <TitleText>Become a Hydrix Affiliate</TitleText>
                <Subtitle>Help us grow and earn rewards!</Subtitle>
                <ApiTitleText>Choose a Category</ApiTitleText>
                <UserAccount>
                    <IconContainer>
                        <ImageA src={MobileCasual} />
                    </IconContainer>
                    <UserTitleText>Casual</UserTitleText>
                    <UserInviteText>Users in this category are regular participants who consistently
                                use the app for mining but have limited involvement in external promotion
                                or community engagement. However, they regularly like and share social media posts
                                and occasionally leave positive comments.
                    </UserInviteText>
                </UserAccount>
                <ButtonA>
                    <ButtonText>Coming Soon</ButtonText>
                </ButtonA>
                <Message>*25% Mining Bonus</Message>
                <UserAccount>
                    <IconContainer>
                        <ImageA src={MobileSupporter} />
                    </IconContainer>
                    <UserTitleText>Supporter</UserTitleText>
                    <UserInviteText>Supporters go beyond casual use. They actively engage in the Hydrix
                                community by sharing app updates, inviting friends to join, and contributing to
                                discussions. They promote the app regularly on their social media,
                                encouraging others to try it.
                    </UserInviteText>
                </UserAccount>
                <ButtonA>
                    <ButtonText>Coming Soon</ButtonText>
                </ButtonA>
                <Message>*50% Mining Bonus</Message>
                <UserAccount>
                    <IconContainer>
                        <ImageA src={MobileAffiliates} />
                    </IconContainer>
                    <UserTitleText>Influencer</UserTitleText>
                    <UserInviteText>Influencers are key advocates of Hydrix, consistently promoting the app
                                across social media, blogs, or other platforms. They generate significant
                                engagement through content creation, such as reviews, guides, or sharing their
                                experience, helping to attract and retain a wider user base.
                    </UserInviteText>
                </UserAccount>
                <ButtonA>
                    <ButtonText>Coming Soon</ButtonText>
                </ButtonA>
                <Message>*100% Mining Bonus</Message>
                <SubtitleB>Access our desktop site for more detailed infomation</SubtitleB>

                
            </Wrapper>
            <HomePageFifth />
        </Container>
    )
};

export default MobileAffiliate;
