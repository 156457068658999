import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import NewFooter from '../components/NewFooter';
import Novice from '../assets/novice.png';
import Middle from '../assets/middle.png';
import End from '../assets/end.png';
import Arrow from '../assets/arrows.png';
import One from '../assets/one.png';
import Two from '../assets/number-2.png';
import Three from '../assets/number-3.png';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    @media screen and (max-width: 900px) {
        height: auto;
    }
    `
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    }
    `
const ImageIcon = styled.img`
    width: 45px;
    height: 45px;
    `
const IconContainer = styled.div`
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;}
    `
const InnerInnerContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    flex-direction: column;
    }
    `
const Casual = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #001226;
    border: 0.5px solid #333333;
    border-radius: 4px;
    height: 300px;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    width: 90vw;
    padding-bottom: 20px;
    height: auto;}
    `
const HowWhat = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #333333;
    border-radius: 4px;
    height: 300px;
    padding: 20px 20px 20px 40px;
    margin-bottom: 20px;
    justify-content: center;
    @media screen and (max-width: 900px) {
    width: 90vw;
    align-items: center;
    padding-bottom: 20px;
    height: auto;}
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center;
    font-size: 18px;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;}
    `
const SubTitleTextA = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    width:80%;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 80%;
    text-align: center;}
    `
const SubTitleTextB = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    width:90%;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 80%;
    text-align: center;}
    `
const HowWhatColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 22%;
    height: 375px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 900px) {
    height: auto;
    width: 80vw;
    margin-bottom: 40px;}
    `
const Button = styled.button`
    padding: 10px 20px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
        background-color: #999999;
    }
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
    `
const RadialGradientE = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 350px;
    height: 350px;
    position: absolute;
    top: 1000px;
    left: -50px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;}
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientF = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 600px;
    height: 600px;
    position: absolute;
    top: 800px;
    right: 0;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.3) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -150px;
    right: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const Form = styled.form`
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    `
const Message = styled.div`
  font-family: Work Sans;
  font-weight: 400;
  font-size: 10px;
  color: #999999;
  margin-top: 10px;
  @media screen and (max-width: 900px) {
        font-size: 8px;
    }
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 25px;
    flex-direction: column;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    width: 100%;
    align-items: center;
    justify-content: center;}
    `
const CasualColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 32%;
    @media screen and (max-width: 900px) {
    height: auto;
    width: 90vw;
    margin-bottom: 40px;}
    `

const Affiliate = () => {
    
    return (
        <Container>
            <Header />
            <RadialGradient />
            <RadialGradientA />
            <RadialGradientB />
            <RadialGradientE />
            <RadialGradientF />
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>What is an Affiliate?</TitleText>
                        <SubTitleTextB>Anyone can become a Hydrix affiliate—you don’t need to be a key
                            opinion leader or have a large following. Whether you're a casual user or
                            someone who enjoys sharing cool apps with friends, you can start earning
                            bonuses by promoting Hydrix. It's easy to get involved, and anyone who
                            participates in the community or spreads the word across social media
                            and other platforms can qualify for rewards. We value every contribution,
                            big or small, so join our affiliate program and start earning today!
                        </SubTitleTextB>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerContainer>
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Get Involved</TitleText>
                            <SubTitleTextB>Start by actively engaging with the Hydrix community and sharing
                                the app on your social media, blogs, or other platforms. It's not just
                                about the number of people you reach—it's about the quality of your efforts.
                                Share informative, thoughtful content about Hydrix, encouraging others to
                                explore the app and its benefits.
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={One} />
                    </HowWhatColumn>
                    {!isMobile && <img src={Arrow} />}
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Effort Over Impact</TitleText>
                            <SubTitleTextB>While having a broad reach is great, it's the consistency and quality
                                of your efforts that matter most. We’re looking for users who genuinely engage
                                with the platform and put in the effort to promote it in creative, authentic ways.
                                Your efforts don’t have to go viral to make a difference! We love those who create!
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={Two} />
                    </HowWhatColumn>
                    {!isMobile && <img src={Arrow} />}
                    <HowWhatColumn>
                        <HowWhat>
                            <TitleText>Ensure Visibility</TitleText>
                            <SubTitleTextB>Make sure your promotions and contributions can be verified by our
                                communications team. Whether it's social media posts, blog articles, or community
                                discussions, we need to confirm your activities before accepting you as an
                                affiliate. Keep a record of your efforts and make them easily accessible for
                                verification.
                            </SubTitleTextB>
                        </HowWhat>
                        <ImageIcon src={Three} />
                    </HowWhatColumn>
                    
                </InnerInnerContainer>
            </InnerContainer>
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Become a Hydrix Affiliate</TitleText>
                        <SubTitleText>Choose a category that describes you most accurately</SubTitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerContainer>
                    <CasualColumn>
                        <Casual>
                            <IconContainer>
                                <ImageIcon src={Novice} style={{ marginLeft: '7.5px' }}/>
                            </IconContainer>
                            <TitleText>Casual</TitleText>
                            <SubTitleTextA>Users in this category are regular participants who consistently
                                use the app for mining but have limited involvement in external promotion
                                or community engagement. However, they regularly like and share social media posts
                                and occasionally leave positive comments.
                                
                            </SubTitleTextA>
                        </Casual>
                        <Button>
                            <ButtonText>Coming Soon</ButtonText>
                        </Button>
                        <Message>*25% Mining Bonus</Message>
                    </CasualColumn>
                    <CasualColumn>
                        <Casual>
                            <IconContainer>
                                <ImageIcon src={Middle} />
                            </IconContainer>
                            <TitleText>Supporter</TitleText>
                            <SubTitleTextA>Supporters go beyond casual use. They actively engage in the Hydrix
                                community by sharing app updates, inviting friends to join, and contributing to
                                discussions. They promote the app regularly on their social media,
                                encouraging others to try it.</SubTitleTextA>
                        </Casual>
                        <Button>
                            <ButtonText>ComingSoon</ButtonText>
                        </Button>                        
                        <Message>*50% Mining Bonus</Message>
                    </CasualColumn>
                    <CasualColumn>
                        <Casual>
                            <IconContainer>
                                <ImageIcon src={End} />
                            </IconContainer>
                            <TitleText>Influencer</TitleText>
                            <SubTitleTextA>Influencers are key advocates of Hydrix, consistently promoting the app
                                across social media, blogs, or other platforms. They generate significant
                                engagement through content creation, such as reviews, guides, or sharing their
                                experience, helping to attract and retain a wider user base.</SubTitleTextA>
                        </Casual>
                        <Button>
                            <ButtonText>Coming Soon</ButtonText>
                        </Button>
                        <Message>*100% Mining Bonus</Message>
                    </CasualColumn>
                </InnerInnerContainer>
            </InnerContainer>
            
            
            <NewFooter />
        </Container>
        
    );
};

export default Affiliate;
