import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DiscordTrans from '../../assets/discordTrans.png';
import TelegramTrans from '../../assets/telegramTrans.png';
import FacebookTrans from '../../assets/facebookTrans.png';
import RedditTrans from '../../assets/redditTrans.png';
import TikTokTrans from '../../assets/tiktokTrans.png';
import TwitterTrans from '../../assets/twitterTrans.png';
import MediumTrans from '../../assets/mediumTrans.png';
import Marquee from 'react-fast-marquee';
import DiscordGray from '../../assets/discordGray.png';
import RedditGray from '../../assets/redditGray.png';
import FacebookGray from '../../assets/facebookGray.png';
import TelegramGray from '../../assets/telegramGray.png';
import TwitterGray from '../../assets/twitterGray.png';
import MediumGray from '../../assets/mediumGray.png';
import TikTokGray from '../../assets/tiktokGray.png';
import { isMobile } from 'react-device-detect';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 65vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 30px;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    display: none;}
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 18px;
    text-align: center;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 300px;
    zIndex: 19;
     `
const CommunityComponentText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 18px;
    padding-right: 20px;
    @media screen and (max-width: 900px) {
    font-size: 12px;}
    `
const IconContainer = styled.div`
    width: 75px;
    height: 75px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const CommunityContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @media screen and (max-width: 900px) {
    justify-content: center;
  }
    `
const CommunityComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    background-color: #00011c;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    display: none;

    `
const CommunityComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    }
    `
const CommunityComponentA = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    }

    `
const CommunityComponentImageA = styled.img`
    width: 24px;
    height: 24px;
    padding-right: 20px;
    `
const CommunityContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 15px;
    width: 80%;
    `
const CommunityTitleText = styled.div`
    color: #999999;
    font-family: Sans-serif;
    font-weight: 500;
    font-size: 14px;
    
    `
const IconRow = styled.div`
    width: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 40px;
    border-bottom: 1px solid #222222;
    padding-bottom: 40px;
    @media screen and (max-width: 900px) {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, 1fr); 
    gap: 20px; 
    `
const CommunityOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.3));
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const MarqueeComponent = styled.div`
    width: 75px;
    height: 75px;
    margin: 25px;
    `
const ImageWrapper = styled.img`
    @media screen and (max-width: 900px) {
    width: 30px;
    height: 30px;}`


const SecondContainer = ({ RadialGradient }) => {
    return (
        <Container>
            <RadialGradient />
            <RadialGradientB />
            
            
            
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Explore The Hydrix Ecosystem</TitleText>
                        <SubTitleText>Check out the latest from our amazing community and stay up to date!</SubTitleText>
                    </TopMasterRowColumn>
                    <IconContainer>
                        <FontAwesomeIcon icon={faArrowRight} color="white" size="1x" />
                    </IconContainer>
                </TopMasterRow>
                <CommunityContainer>
                    <CommunityComponent>
                        <Marquee direction="right" speed={50}>
                            <MarqueeComponent>
                                <img src={DiscordGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TelegramGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={RedditGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={FacebookGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={MediumGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TwitterGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TikTokGray} />
                            </MarqueeComponent>
                        </Marquee>
                        <Marquee direction="left" speed={50}>
                            <MarqueeComponent>
                                <img src={MediumGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TelegramGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TikTokGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={FacebookGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={DiscordGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={TwitterGray} />
                            </MarqueeComponent>
                            <MarqueeComponent>
                                <img src={RedditGray} />
                            </MarqueeComponent>
                        </Marquee>   
                    </CommunityComponent>
                    <CommunityComponentA>
                        <TitleText>Our Channels</TitleText>
                        <SubTitleText>
                            At Hydrix we make announcements via official channels however, 
                            members of our community operate community owned channels which
                            you are free to join. Community channels that we are aware of are 
                            linked here.
                        </SubTitleText>
                        <SubTitleText>
                            Please check this webpage for official news and promotions and only 
                            make decisions based on information you can confirm comes from Hydrix.
                        </SubTitleText>
                        <a href="https://www.t.me/HydrixApp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <ButtonA>
                            <ButtonText>Get Updates</ButtonText>
                        </ButtonA>
                        </a>
                    </CommunityComponentA>
                </CommunityContainer>
                
            </InnerContainer>
                
        </Container>

    )
};

export default SecondContainer;