import styled from "styled-components";
import NewFooter from "../components/NewFooter";
import { useState } from "react";
import Header from "../components/Header";
import TotalUsers from '../assets/totalUsers.png';
import TotalMined from '../assets/totalMined.png';
import ActiveUsers from '../assets/activeUsers.png';
import UserGrowth from '../assets/userGrowth.png';
import NewUsers from '../assets/newUsers.png';
import Lottery from '../assets/lottery.png';
import Download from '../assets/download.png';
import Design from '../assets/design.png';
import Version from '../assets/repository.png';
import Account from '../assets/account.png';
import { isMobile } from "react-device-detect";


const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    @media screen and (max-width: 900px) {
        height: auto;
    }
`
const InnerInnerContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    flex-direction: column;
    }
    `
const InnerInnerInnerContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
  
    @media screen and (max-width: 900px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px; /* Optional: spacing between grid items */
      margin-top: 25px;
      width: 90%;
      max-width: 90%;
      height: auto;
      justify-content: center;
      margin-top: 0;
    }`
const InnerContainer = styled.div`
    width: 80%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-top: 25px;
    width: 90%;
    max-width: 90%;
    height: auto;
    }
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.2) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 900px;
    left: -50px;
    zIndex: 19;
    `
const ImageIcon = styled.img`
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
    width: 30px;
    height: 30px;}
    `
const ImageIconA = styled.img`
    width: 45px;
    height: 45px;
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 40px;
    flex-direction: column;
    }
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    width: 100%;
    align-items: center;
    justify-content: center;}
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    @media screen and (max-width: 900px) {
    font-size: 16px;
    width: 100%;
    text-align: center;}
    `
const SubTitleTextB = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    @media screen and (max-width: 900px) {
    font-size: 14px;
    width: 80%;
    text-align: center;}
    `
const SubTitleTextC = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    text-align: right;
    @media screen and (max-width: 900px) {
    font-size: 14px;
    width: 80%;
    text-align: center;}
    `
const ResourceColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 150px;
    height: 200px;
    text-align: center;
    @media screen and (max-width: 900px) {
    width: 120px;
    height: 80px;}
    `
const ResourceTitle = styled.div`
    font-family: Work Sans;
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    @media screen and (max-width: 900px) {
    font-weight: 400;
    font-size: 12px;}
    `
const ResourceSubtitle = styled.div`
    font-family: Work Sans;
    color: #999999;
    font-weight: 400;
    font-size: 12px;
    tex-align: center;
    width: 90%;
    @media screen and (max-width: 900px) {
    font-size: 10px;}
    `
const CommunityComponentA = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 48%;
    border-radius: 4px;
    align-items: flex-end;
    justify-content: space-around;
    height: 300px;
    @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;}
    `
const CommunityComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 48%;
    background-color: #001226;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    @media screen and (max-width: 900px) {
    margin-top: 40px;
    width: 90%;

    `
const IconContainer = styled.div`
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;}
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 20px;
    &:hover {
    background-color: #999999;
    }
    `
const ButtonText = styled.div`
    color: black;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const FormContainer = styled.form`
    width: 90%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `
const Input = styled.input`
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: rgba(0, 250, 217, 0.1);
    ` 
const Api = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    return (
        <Container>
            <Header />
            {!isMobile &&<RadialGradient />}
            {!isMobile &&<RadialGradientA />}
            {!isMobile &&<RadialGradientB />}
            {!isMobile &&<RadialGradientC />}
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Hydrix API</TitleText>
                        <SubTitleTextB>At Hydrix, we believe in empowering our community with the
                            tools they need to stay connected and informed. That's why we've built
                            a free API, enabling users to seamlessly access and display key data 
                            from our platform. Whether you're a developer looking to integrate Hydrix
                            data into your projects, or a community member wanting to share insights
                            in real-time, our API ensures that you can easily fetch and showcase the
                            latest information.
                            By providing open access to our data, we aim to foster innovation and
                            transparency, encouraging users to create unique, valuable experiences
                            around Hydrix. We’re excited to see how you’ll bring our data to life in
                            your own applications!
                            
                        </SubTitleTextB>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <InnerInnerInnerContainer>
                    <ResourceColumn>
                        <ImageIcon src={TotalUsers} />
                        <ResourceTitle>/total_users</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={TotalMined} />
                        <ResourceTitle>/total_mined</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={ActiveUsers} />
                        <ResourceTitle>/active_users</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={UserGrowth} />
                        <ResourceTitle>/users_Growth</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={NewUsers} />
                        <ResourceTitle>/new_users</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                </InnerInnerInnerContainer>
                <InnerInnerInnerContainer>
                    <ResourceColumn>
                        <ImageIcon src={Lottery} />
                        <ResourceTitle>/lotto_win</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={Download} />
                        <ResourceTitle>/downloads_ios</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={Design} />
                        <ResourceTitle>/hydrix_logo</ResourceTitle>
                        <ResourceSubtitle>Returns a string in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={Download} />
                        <ResourceTitle>/downloads_android</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                    <ResourceColumn>
                        <ImageIcon src={Version} />
                        <ResourceTitle>/current_version</ResourceTitle>
                        <ResourceSubtitle>Returns an integer in a JSON object</ResourceSubtitle>
                    </ResourceColumn>
                </InnerInnerInnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Get your API key</TitleText>
                        <SubTitleTextB>Request, delete, or replace an API key.</SubTitleTextB>
                    </TopMasterRowColumn>
                    <ButtonA>
                        <ButtonText>Explore Examples</ButtonText>
                    </ButtonA>
                </TopMasterRow>
                <InnerInnerContainer>
                    <CommunityComponentA>
                        <IconContainer>
                            <ImageIconA src={Account} />
                        </IconContainer>
                        <TitleText>Create User Account</TitleText>
                        <SubTitleTextC>
                            Create a user account today to gain access to the Hydrix API and 
                            receive your unique API key! This account is specifically for
                            accessing our API and is separate from your Hydrix mining account.
                            With your API key, you'll be able to fetch and display real-time
                            data from our platform, enabling you to build powerful integrations
                            and applications around Hydrix. Signing up is quick and easy,
                            and once you're registered, you'll be ready to start using our free API.
                        </SubTitleTextC>
                    </CommunityComponentA>
                    <CommunityComponent>
                        <FormContainer>
                            <TitleText>Your Details</TitleText>
                            
                                <Input
                                    type="text"
                                    placeholder="Create username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Create password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                            <ButtonA>
                                <ButtonText>Coming Soon</ButtonText>
                            </ButtonA>
                        </FormContainer>
                    </CommunityComponent>
                </InnerInnerContainer>
            </InnerContainer>
            <NewFooter />
        </Container>
    )
};

export default Api;