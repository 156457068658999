import styled from "styled-components";
import { useState, useEffect } from 'react';
import twitterFooterLogo from '../assets/twitterFooterLogo.png';
import telegramFooterLogo from '../assets/telegramFooterLogo.png';
import facebookFooterLogo from '../assets/facebookFooterLogo.png';
import discordFooterLogo from '../assets/discordFooterLogo.png';
import redditFooterLogo from '../assets/redditFooterLogo.png';
import mediumFooterLogo from '../assets/mediumFooterLogo.png';
import youtubeFooterLogo from '../assets/youtubeFooterLogo.png';
import tiktokFooterLogo from '../assets/tiktokFooterLogo.png';





const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 60vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #040026;
    @media screen and (max-width: 900px) {
    height: auto;
    }
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    height: 60%;
    display: flex;
    position: relative;
    flex-direction: row;
    border-bottom: 1px solid #222222;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    }
    `
const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    margin-bottom: 15px;}
    `
const FooterColumnRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    `
const FooterColumnTitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
    @media screen and (max-width: 900px) {
    text-align: center;}
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;
    &:hover {
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}   @media screen and (max-width: 900px) {
    text-align: center;}
    `
const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: rgba(0, 250, 217, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    &:hover {
    background-color: rgba(255, 255, 255, 0.15);
}
    `
const Image = styled.img`
    width: 14px;
    height: 14px;
    `
const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin-top: 20px;
    @media screen and (max-width: 900px) {
    width: 95%;}
    `
const Modal = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 800px;
    height: 250px;
    background-color: #0F0F0F;
    border-radius: 24px;
    transform: translate(-50%, -50%);
    z-index: 99999;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #222222;
    @media screen and (max-width: 900px) {
    width: 87.5%;
    height: auto;
    top: 70%;
    padding-top: 25px;
    padding-bottom: 25px;
    }

    `
const EmailModal = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 400px;
    height: 100px;
    background-color: #0F0F0F;
    border-radius: 24px;
    transform: translate(-50%, -50%);
    z-index: 99999;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    border: 1px solid #222222;
    @media screen and (max-width: 900px) {
    width: 87.5%;
    height: auto;
    top: 70%;
    padding-top: 25px;
    padding-bottom: 25px;
    }

    `


const NewFooter = () => {

    const currentYear = new Date().getFullYear();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

    return (
        <Container>
            <InnerContainer>
                
                <FooterColumn>
                    <FooterColumnTitleText>Docs</FooterColumnTitleText>
                    <a href="https://drive.google.com/file/d/1n-9f722ZHgD2vCpS5SF2or1FAgBBIilf/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <SubTitleText>Data Protection</SubTitleText>
                    </a>
                    <a href="https://drive.google.com/file/d/1d4BWfbYhY0YmxKiP9MkJ0qed0K-JNfWP/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <SubTitleText>Money Laundering</SubTitleText> 
                    </a>                       
                    <a href="https://drive.google.com/file/d/1D3Snt0OJaCnzB-xW3Eaz1YVyOOL8gjJQ/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <SubTitleText>Terms & Conditions</SubTitleText>
                    </a>
                    <a href="https://drive.google.com/file/d/1aBYwOoAaSDyh3AcMPMo4sIIsPPKvT0SB/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <SubTitleText>Whitepaper</SubTitleText>
                    </a>
                    
                </FooterColumn>
                <FooterColumn>
                    <FooterColumnTitleText>Contact</FooterColumnTitleText>
                    <SubTitleText
                        onClick={() => setIsEmailModalOpen(true)}
                        onMouseLeave={() => setIsEmailModalOpen(false)}
                    >
                        Email
                    </SubTitleText>
                    <SubTitleText>X.com</SubTitleText>
                    <SubTitleText>Telegram</SubTitleText>
                </FooterColumn>
{ isEmailModalOpen && (
                    <EmailModal>
                        <SubTitleText>
                            Contact us: contact@hydrix.me
                        </SubTitleText>
                    </EmailModal>
)}
                <FooterColumn>
                    <FooterColumnTitleText>Download</FooterColumnTitleText>
                    <SubTitleText>Android</SubTitleText>
                    <SubTitleText>ios</SubTitleText>
                </FooterColumn>
                <FooterColumn>
                    <FooterColumnTitleText>Socials</FooterColumnTitleText>
                    <FooterColumnRow>
                    <a href="https://www.twitter.com/hydrixapp" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <IconContainer>
                            <Image src={twitterFooterLogo} />
                        </IconContainer>
                    </a>
                        <IconContainer>
                            <Image src={telegramFooterLogo} />
                        </IconContainer>
                        <IconContainer>
                            <Image src={facebookFooterLogo} />
                        </IconContainer>
                        <IconContainer>
                            <Image src={discordFooterLogo} />
                        </IconContainer>
                    </FooterColumnRow>
                    <FooterColumnRow>
                        <IconContainer>
                            <Image src={redditFooterLogo} />
                        </IconContainer>
                        <IconContainer>
                            <Image src={mediumFooterLogo} />
                        </IconContainer>
                        <IconContainer>
                            <Image src={youtubeFooterLogo} />
                        </IconContainer>
                    <a href="https://www.tiktok.com/@hydrix512" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <IconContainer>
                            <Image src={tiktokFooterLogo} />
                        </IconContainer>
                    </a>
                    </FooterColumnRow>
                        
                </FooterColumn>
            </InnerContainer>
            <BottomRow>
                <SubTitleText
                    style={{ cursor: 'none' }}
                >
                    Copyright Hydrix LTD @ {currentYear}
                </SubTitleText>
                <SubTitleText
                    onClick={() => setIsModalOpen(true)} 
                    onMouseLeave={() => setIsModalOpen(false)}
                    
                >
                    Disclaimer
                </SubTitleText>
{ isModalOpen && (
                <Modal>
                    <SubTitleText>The use of this crypto mining application does not guarantee the launch or successful release of any associated tokens. Participants should understand that this app is provided as-is and is part of an experimental platform.

                                    There should be no expectation of profit or financial return from the use of this app. The value of any tokens, if issued, may be highly volatile, or the tokens may never be released or have no market value. Participation is at your own risk.

                                    We strongly advise all users to conduct their own research and seek independent financial advice before engaging in any mining activities. The app developers and associated entities make no representations or warranties regarding the outcomes or financial returns associated with the use of this platform.</SubTitleText>
                </Modal>
)}
            </BottomRow>
        </Container>

    )
};

export default NewFooter;