import styled from "styled-components";
import FairnessHomePage from '../../assets/fairnessHomePage.png';
import Opinion from '../../assets/opinion.png';
import Efficiency from '../../assets/efficiency.png';

const Container = styled.div`
    width: 100%;
    height: 140vh; 
    display: flex;
    flex-direction: column;
    background-color: #00011c;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    `
const TitleText = styled.div`
    color: #FFFFFF;
    font-size: 42px;
    font-family: Work Sans;
    font-weight: 600;
    margin-bottom: 15px;
    text-align: left;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: #45D9F4;
    font-family: Pridi;
    margin-bottom: 60px;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #00FAD9;
    height: 60px;
    width: 80%;
    margin-top: 30px;
    background-color: #00FAD9; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #00011c;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const InfoComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100;
    border: 0.5px solid #333333;
    border-radius: 4px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 30px;

    
    `
const InfoComponentRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const InfoComponentColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 900px) {
    justify-content: center;
    text-align: center;}
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FF4DCF;
    margin-bottom: 20px;
    
    `
const InviteTitleText = styled.div`
    color: #45D9F4;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;
    `
const InviteText = styled.div`
    color: white;
    font-family: Pridi;
    font-weight: 400;
    font-size: 14px;
    `
const ImageIcon = styled.img`
    height: 40px;
    width: 40px;
    `
const HomePageFourth = () => {
    return (
        <Container>
            <Wrapper>
                <TitleText>Why Hydrix?</TitleText>
                <Subtitle>What you can expect from us</Subtitle>
                <InfoComponent>
                    
                        <IconContainer>
                            <ImageIcon src={FairnessHomePage} />
                        </IconContainer>
                            <InviteTitleText>Fairness</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We have process' in place to ensure that the distribution of
                                Hydrix will be based on Mining activity and engagement only.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                    
                        <IconContainer>
                            <ImageIcon src={Efficiency} />
                        </IconContainer>
                            <InviteTitleText>Development</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>From inception right through to token launch,
                                we spend each day developing the product to make it better.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>
                    <InfoComponent>
                    
                        <IconContainer>
                            <ImageIcon src={Opinion} />
                        </IconContainer>
                            <InviteTitleText>Community</InviteTitleText>
                        
                        <InfoComponentColumn>
                            <InviteText>We place our community at the centre of everything
                                we do at Hydrix. We work on your behalf with gratitude.
                            </InviteText>
                        </InfoComponentColumn>
                    </InfoComponent>

              
            </Wrapper>
            
        </Container>
    )
};

export default HomePageFourth;
