import styled from "styled-components";
import HomeFAQList from "./HomeFAQList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 700px;
    height: 700px;
    position: absolute;
    top: 10%;
    left: -250px;
    zIndex: 20;
    overflow: hidden;
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    overflow: hidden;
    right: 300px;
    zIndex: 19;
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    }
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 0;}
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    `
const Button = styled.button`
    display: flex;
    flex-direction: row;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 2px solid #FF4DCF;
    background-color: #FF4DCF;
    padding: 0px 15px 0px 15px;
    `
const ButtonText = styled.div`
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 600;
    color: black;
    `
const FifthContainer = () => {
    return (
        <Container>
            {!isMobile && <RadialGradient />}
            {!isMobile && <RadialGradientB />}
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>Any Questions?</TitleText>
                        <SubTitleText>Find answers to some common questions or submit your own.</SubTitleText>
                    </TopMasterRowColumn>
                    <a href="https://www.t.me/hydrixcommunityportal" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                        <Button>
                            <ButtonText>Ask a Question</ButtonText>
                            <FontAwesomeIcon icon={faArrowRight} size="1x" color="#001226" />
                        </Button>
                    </a>
                </TopMasterRow>
                <HomeFAQList />
            </InnerContainer>
        </Container>

    )
};

export default FifthContainer;