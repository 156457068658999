import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import NewFooter from '../components/NewFooter';

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    @media screen and (max-width: 900px) {
        height: auto;
    }
    `
const TitleText = styled.div`
    color: white;
    font-size: 58px;
    font-family: Work Sans;
    font-weight: 900;
    margin-bottom: 20px;
    `
const SubTitle = styled.div`
    color: #999999;
    font-size: 22px;
    font-weight: 500;
    font-family: Work Sans;
    text-align: center;
    margin-bottom: 20px;
    `
const SubTitleA = styled.div`
    color: #999999;
    font-size: 12px;
    font-weight: 500;
    font-family: Work Sans;
    text-align: center;
    margin-top: 20px;
    z-index: 20;
    margin-bottom: 225px;
    @media screen and (max-width: 900px) {
        font-size: 10px;
        margin-bottom: 170px;
    }
    `
const InfoDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    z-index: 99;
    margin-top: 50px;
    @media screen and (max-width: 900px) {
        top: 100px;
    }
    `
const LottoComponent = styled.div`
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 50%;
    background-color: #1A0135;
    border-radius: 4px;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 20;
    overflow: hidden;
    @media screen and (max-width: 900px) {
        width: 90%;
        height: auto;
        margin-top: 50px;
    }
    `
const Input = styled.input`
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    width: 80%;
    `
const Button = styled.button`
    padding: 10px 20px;
    background-color: rgba(0, 250, 217, 1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
        background-color: #e67e22;
    }
    `
const RadialGradient = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    left: -250px;
    zIndex: 19;
    `
const RadialGradientA = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.3) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 200px;
    left: 300px;
    zIndex: 19;
    @media screen and (max-width: 900px) {
    display: none;
    }
    `
const RadialGradientB = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 50px;
    zIndex: 19;
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const Form = styled.form`
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    `
const Message = styled.div`
  font-family: Work Sans;
  font-weight: 400;
  font-size: 16px;
  color: white;
  @media screen and (max-width: 900px) {
        font-size: 8px;
    }
    `

const Lotto = () => {
    const [username, setUsername] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Basic validation
        if (!username || !number) {
            setMessage('Please enter both username and number.');
            return;
        }
        // Handle submission logic here
        console.log(`Username: ${username}, Number: ${number}`);
        setMessage(`Successfully entered! Username: ${username}, Number: ${number}`);
        // Clear the inputs
        setUsername('');
        setNumber('');
    };

    return (
        <Container>
            <Header />
            <RadialGradient />
            <RadialGradientA />
            <RadialGradientB />
            
            <InfoDiv>
                <TitleText>Play Hydrix Lotto!</TitleText>
                <SubTitle>Win up to 1000 Hydrix every week!</SubTitle>
            </InfoDiv>
            <LottoComponent>
                <Form onSubmit={handleSubmit} style={{ width: '100%', textAlign: 'center' }}>
                    <Input
                        type="text"
                        placeholder="Enter your username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <Input
                        type="number"
                        placeholder="Pick a number (1-10000)"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <Button type="submit">
                      <ButtonText>Coming Soon</ButtonText>
                    </Button>
                </Form>
                {message && <Message style={{ color: 'white', marginTop: '10px' }}>{message}</Message>}
            </LottoComponent>
            <SubTitleA>*You must be an active Hydrix user to make a valid Lotto pick!</SubTitleA>
            <NewFooter />
        </Container>
        
    );
};

export default Lotto;
