import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import HomePageFifth from "../components/HomePage/HomePageFifth";
import { useState } from "react";
import UserApi from '../assets/userApi.png';


const Container = styled.div`
    width: 100%;
    height: auto;
    background-color: #00011c;
    display: flex;
    flex-direction: column;
    align-items: center;
    `
const Wrapper = styled.div`
    width: 90%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 60px;
    `
const TitleText = styled.div`
    color: white;
    font-size: 42px;
    font-family: Work Sans;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    `
const ApiTitleText = styled.div`
    color: white;
    font-size: 30px;
    font-family: Work Sans;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    `
const HeaderRow = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    `
const TitleContainer = styled.div`
    width: 30%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    `
const Image = styled.img`
    width: 40px;
    height: 30px;
    margin-right: 5px;
    `
const ImageA = styled.img`
    width: 30px;
    height: 30px;
    
    `
const Subtitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #FF4DCF;
    font-family: Pridi;
    text-align: left;
    `
const SubtitleB = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: #45D9F4;
    font-family: Work Sans;
    text-align: left;
    margin-top: 30px;
    `
const ButtonA = styled.button`
    border-radius: 4px;
    border: 2px solid #FF4DCF;
    height: 40px;
    width: 60%;
    margin-top: 30px;
    background-color: #FF4DCF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around; 
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #1A0135;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `
const ScreenShotImg = styled.img`
    width: 70%;
    height: 300px;
    `
const ImageRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    `
const ImageRowA = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    `
const IconContainer = styled.div`
    height: 60px;
    width: 60px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: #FF4DCF;
    margin-bottom: 20px;
    `
const ImagePhone = styled.img`
    width: 60%;
    height: auto;
    `
const FormContainer = styled.form`
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 60px;
    `
const Input = styled.input`
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #8e8e8e;
    `
const UserAccount = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid #333333;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;
    `
const UserTitleText = styled.div`
    color: #45D9F4;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
   `
const UserInviteText = styled.div`
   color: white;
   font-family: Pridi;
   font-weight: 400;
   font-size: 14px;
   text-align: center;
   width: 80%;
   `
const FloatingButton = styled.button`
    position: fixed;
    top: 15px;
    right: 15px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #45D9F4;
    border: 2px solid #45D9F4;
    display: flex;
    align-items: center;
    justify-content: center;
    `
const FloatingButtonText = styled.div`
    font-family: Work Sans;
    font-weight: 600;
    font-size: 12px;
    color: black;
    `
const MobileApi = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    return (
        <Container>
            <FloatingButton>
                <FloatingButtonText>See Examples</FloatingButtonText>
            </FloatingButton>
            <Wrapper>
                <TitleText>Developers</TitleText>
                <Subtitle>Access our free api and get creative!</Subtitle>
                <UserAccount>
                    <IconContainer>
                        <ImageA src={UserApi} />
                    </IconContainer>
                    <UserTitleText>Create Account</UserTitleText>
                    <UserInviteText>Create a user account today to gain access to the Hydrix API and 
                            receive your unique API key! This account is specifically for
                            accessing our API and is separate from your Hydrix mining account.
                            With your API key, you'll be able to fetch and display real-time
                            data from our platform, enabling you to build powerful integrations
                            and applications around Hydrix. Signing up is quick and easy,
                            and once you're registered, you'll be ready to start using our free API.
                    </UserInviteText>
                </UserAccount>
                <FormContainer>
                            <ApiTitleText>Get Key</ApiTitleText>
                            
                                <Input
                                    type="text"
                                    placeholder="Create username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder="Create password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                            <ButtonA>
                                <ButtonText>Coming Soon</ButtonText>
                            </ButtonA>
                            <SubtitleB>Access our desktop site for more detailed infomation</SubtitleB>

                        </FormContainer>

            </Wrapper>
            <HomePageFifth />
        </Container>
    )
};

export default MobileApi;
