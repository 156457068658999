import styled from "styled-components";
import Wallet from '../../assets/wallet.png';
import Mine from '../../assets/mine.png';
import Water from '../../assets/water.png';
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const Container = styled.div`
    width: 100%;
    max-width: 100%;
    height: 120vh;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #00011c;
    `
const InnerContainer = styled.div`
    width: 70%;
    max-width: 80%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    @media screen and (max-width: 900px) {
    width: 90%;
    max-width: 90%;
    height: auto;
    margin-top: 25px;
    }
    `
const TopMasterRow = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 75px;
    @media screen and (max-width: 900px) {
    margin-bottom: 30px;
    }
    `
const RadialGradientC = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    top: 0px;
    right: 300px;
    zIndex: 19;
    `
const RadialGradientD = styled.div`
    background: radial-gradient(circle, rgba(193, 2, 196, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0px;
    left: 300px;
    zIndex: 19;
    `
const RadialGradientE = styled.div`
    background: radial-gradient(circle, rgba(0, 250, 217, 0.1) 30%, #00011c 70%);
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: 0px;
    left: 300px;
    zIndex: 19;
    `
const TopMasterRowColumn = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    @media screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
    width: 100%;
    }
    `
const TitleText = styled.div`
    color: white;
    font-family: Work Sans;
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
    font-size: 18px;
    width: 100%;
    text-align: center;
    align-items: center;}
    `
const IconContainer = styled.div`
    height: 70px;
    width: 70px;
    border-radius: 35px;
    background-color: rgba(0, 250, 217, 0.3);
    align-items: center;
    margin-right: 40px;
    justify-content: center;
    display: flex;
    @media screen and (max-width: 900px) {
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;}
    
    `
const SubTitleText = styled.div`
    color: #999999;
    font-family: Work Sans;
    font-weight: 500;
    font-size: 16px;
    @media screen and (max-width: 900px) {
    width: 100%;
    text-align: center; }
    `
const HydrixWallet = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 5px solid #999999;
    border-radius: 4px;
    width: 99%;
    height: 200px;
    @media screen and (max-width: 900px) {
    height: auto;
    flex-direction: column;}
    `
const HydrixWalletInner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 70%;
    width: 90%;
    @media screen and (max-width: 900px) {
    flex-direction: column;}
    `
const HydrixWalletColumn = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: flex-start;
    justify-content: flex-start;
    `
const HydrixWalletColumnMLeft = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
    @media screen and (max-width: 900px) {
    margin-left: 0;}
    `
const FutureRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 225px;
    margin-top: 20px;
    margin-bottom: 50px;
    @media screen and (max-width: 900px) {
    flex-direction: column;
    height: auto;}
    `
const FutureRowLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 49%;
    height: 100%;
    border: 0.5px solid #333333;
    border-radius: 4px;
    @media screen and (max-width: 900px) {
    height: 48%;
    width: 90vw;
    margin-bottom: 20px;
    flex-direction: column;
    padding-bottom: 20px;}
    `
const FutureRowRight = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    align-items: center;
    justify-content: flex-start;
    width: 49%;
    height: 100%;
    border: 0.5px solid #333333;
    @media screen and (max-width: 900px) {
    height: 48%;
    width: 90vw;
    margin-bottom: 20px;
    flex-direction: column;
    padding-bottom: 20px;}
    `
const Image = styled.img`
    width: 40px;
    height: 40px;
    
    `
const ButtonA = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border: 2px solid #FF4DCF;
    padding: 15px;
    margin: 75px 0px 0px 75px;
    width: 200px;
    @media screen and (max-width: 900px) {
    margin: 20px 0px 20px 0px;}
    `
const ButtonC = styled.button`
    border-radius: 4px;
    background-color: #FF4DCF;
    border 2px solid #FF4DCF;
    padding: 15px;
    width: 200px;
    margin-top: 40px;
    
    `
const ButtonB = styled.button`
    border-radius: 16px;
    border: 2px solid #00FAD9;
    background-color: #00FAD9;
    padding: 15px;
    margin: 10px;
    width: 100px;
    
    `
const ButtonText = styled.div`
    color: #001B39;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px
    `



const SixthContainer = ({ RadialGradient, RadialGradientB }) => {
    return (
        <Container>
            {!isMobile && <RadialGradient />}
            {!isMobile &&<RadialGradientC />}
            {!isMobile &&<RadialGradientD />}
            {!isMobile &&<RadialGradientE />} 
            <InnerContainer>
                <TopMasterRow>
                    <TopMasterRowColumn>
                        <TitleText>The Future</TitleText>
                        <SubTitleText>Explore what the future has in store for Hydrix!</SubTitleText>
                    </TopMasterRowColumn>
                </TopMasterRow>
                <HydrixWallet>
                    <HydrixWalletInner>
                        <HydrixWalletColumn>
                            <IconContainer>
                                <Image src={Wallet} />
                            </IconContainer>
                        </HydrixWalletColumn>
                        <HydrixWalletColumn style={{ width: '60%' }}>
                            <TitleText>Hydrix Wallet</TitleText>
                            <SubTitleText>Explore the latest in our journey as we develop Hydrix Wallet.
                                 Buy, sell, and hold your Hydrix while exploring the world of Web3.
                                
                            </SubTitleText>
                        </HydrixWalletColumn>
                        <Link to="/future">
                            <ButtonA>
                                <ButtonText>Explore</ButtonText>
                            </ButtonA>
                        </Link>
                    </HydrixWalletInner>
                    
                </HydrixWallet>
                <FutureRow>
                    <FutureRowLeft>
                        <HydrixWalletColumnMLeft>
                            <IconContainer>
                                <Image
                                    src={Mine}
                                />
                            </IconContainer>
                        </HydrixWalletColumnMLeft>
                        <HydrixWalletColumn style={{ width: '60%' }}>
                            <TitleText>After Miner</TitleText>
                            <SubTitleText>Hydrix users can lock their mining balance (or buy) an
                                afterminer. This replaces staking, capping Hydrix inflation at 10%. Once the mining pool
                                is gone, its gone forever!
                                
                            </SubTitleText>
                        </HydrixWalletColumn>
                    </FutureRowLeft>
                    <FutureRowRight>
                        <HydrixWalletColumnMLeft>
                            <IconContainer>
                                <Image
                                    src={Water}
                                />
                            </IconContainer>
                        </HydrixWalletColumnMLeft>
                        <HydrixWalletColumn style={{ width: '60%' }}>
                            <TitleText>LP Rewards</TitleText>
                            <SubTitleText>Hydrix will depoly our own smart contract (LP farm)
                                 to reward those users that provide liquidity for Hydrix after 
                                 we launch. Check back here for more details</SubTitleText>
                        </HydrixWalletColumn>
                    </FutureRowRight>
                </FutureRow>
                <TitleText>Win Some Free Hydrix?</TitleText>
                <SubTitleText>Play the free-to-enter Hydrix Lotto and win up to 1000 Hydrix each week!</SubTitleText>
                <Link to="/lotto">
                    <ButtonC>
                        <ButtonText>Enter</ButtonText>
                    </ButtonC>
                </Link>
            </InnerContainer>
        </Container>

    )
};

export default SixthContainer;